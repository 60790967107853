import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import BlogCard from "../BlogCard/BlogCard";
import AOS from "aos";
import "aos/dist/aos.css";
function BlogsSection({ blogsData }) {
  useEffect(() => {
    AOS.init({ duration: 1700 });
  });
  return (
    <Container className="py-5 text-center text-lg-end">
      <Row>
        <Col
          xs="12"
          lg="4"
          className="p-lg-5 p-3 pe-lg-0 "
          data-aos="flip-right"
        >
          <p className="text-danger m-0 mb-2 font2">
            المقالات{" "}
          </p>
          <h1 className="fw-bold font2"> أحدث المقالات</h1>
          <p>تصفح أحدث المقالات للكاتب د.إسماعيل بن محمد السلامات</p>
          <Link to="blogs">
            <button className="btn btn-danger px-5 py-3 rounded-0 ">
              اطلع على المزيد
              <i className="fa-solid fa-arrow-left-long pe-2"></i>
            </button>
          </Link>
        </Col>
        {blogsData.map((item) => (
          <Col xs="12" lg="4" className="p-3" key={item.id}>
            <BlogCard key={item.id} blogData={item} />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default BlogsSection;
