import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import blogReducer from "./reducers/blogsReducer";
import bookReducer from "./reducers/booksReducer";

const reducers = combineReducers({
  blogs: blogReducer,
  books: bookReducer,
});
export const store = createStore(reducers, applyMiddleware(thunk));
