import {
  END_PROCCESS_BLOG,
  SET_ALL_BLOGS,
  GET_DATA_FAIL_BLOG,
  START_PROCCESS_BLOG,
} from "../constant/blogsConstant";

export default function blogReducer(
  state = { blogsData: [], errorMessage: "", blogProccess: false },
  action
) {
  switch (action.type) {
    case START_PROCCESS_BLOG:
      return { ...state, blogProccess: true };
    case END_PROCCESS_BLOG:
      return { ...state, blogProccess: false };
    case SET_ALL_BLOGS:
      return { ...state, blogsData: action.payload };

    case GET_DATA_FAIL_BLOG:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
}
