import { db } from "../../firebase/firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import {
  END_PROCCESS_BLOG,
  GET_DATA_FAIL_BLOG,
  SET_ALL_BLOGS,
  START_PROCCESS_BLOG,
} from "../constant/blogsConstant";

export const getAllBlogs = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: START_PROCCESS_BLOG,
      });
      const arr = [];
      const querySnapshot = await getDocs(collection(db, "blogs"));
      querySnapshot.forEach((doc) => {
        arr.unshift({ ...doc.data(), id: doc.id });
      });

      dispatch({
        type: SET_ALL_BLOGS,
        payload: arr,
      });
      dispatch({ type: END_PROCCESS_BLOG });
    } catch (error) {
      dispatch({ type: GET_DATA_FAIL_BLOG, payload: error });
      dispatch({ type: END_PROCCESS_BLOG });
    }
  };
};
