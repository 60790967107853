import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCJTVkRjAa-Bia9-tOPt2bjI3eQE_zjI5o",
  authDomain: "author-project-1e545.firebaseapp.com",
  projectId: "author-project-1e545",
  storageBucket: "author-project-1e545.appspot.com",
  messagingSenderId: "1081584697266",
  appId: "1:1081584697266:web:c315ed33bedfbcf264aeab",
};

const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth();

// init Storage
export const storage = getStorage(firebaseApp);

// init firestore8
export const db = getFirestore(firebaseApp);
