import { db } from "../../firebase/firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import {
  END_PROCCESS_BOOK,
  GET_DATA_FAIL_BOOK,
  SET_ALL_BOOKS,
  START_PROCCESS_BOOK,
} from "../constant/booksConstant";
import {} from "../constant/blogsConstant";

export const getAllBooks = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: START_PROCCESS_BOOK,
      });
      const arr = [];
      const querySnapshot = await getDocs(collection(db, "books"));
      querySnapshot.forEach((doc) => {
        arr.unshift({ ...doc.data(), id: doc.id });
      });

      dispatch({
        type: SET_ALL_BOOKS,
        payload: arr,
      });
      dispatch({ type: END_PROCCESS_BOOK });
    } catch (error) {
      dispatch({ type: GET_DATA_FAIL_BOOK, payload: error });
      dispatch({ type: END_PROCCESS_BOOK });
    }
  };
};
