import NavBar from "./components/NavBar/NavBar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { lazy, Suspense, useEffect } from "react";
import NotFoundPage from "./pages/NotFoundPage";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getAllBooks } from "./redux/actions/booksActions";
import { getAllBlogs } from "./redux/actions/blogsAction";
import Sppiner from "./components/Sppiner/Sppiner";
const AboutUsPage = lazy(() => import("./pages/AboutUsPage"));
const BlogPage = lazy(() => import("./pages/BlogPage"));
const BlogsPage = lazy(() => import("./pages/BlogsPage"));
const ContactUsPage = lazy(() => import("./pages/ContactUsPage"));
const BookPage = lazy(() => import("./pages/BookPage"));
const BooksPage = lazy(() => import("./pages/BooksPage"));

function App() {
  let sel = useSelector((state) => state);
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllBlogs());
    dispatch(getAllBooks());
  }, [dispatch]);
  return (
    <BrowserRouter>
      <NavBar />
      <Suspense fallback={<Sppiner />}>
        <Routes>
          <Route
            path={"/"}
            element={
              <HomePage
                booksData={sel.books.booksData}
                blogsData={sel.blogs.blogsData}
              />
            }
          />
          <Route
            path={"/aboutUs"}
            element={
              <AboutUsPage
                blogsData={sel.blogs.blogsData}
                booksData={sel.books.booksData}
              />
            }
          />
          <Route
            path={"/blogs"}
            element={<BlogsPage blogsData={sel.blogs.blogsData} />}
          />
          <Route path={"/contact"} element={<ContactUsPage />} />

          <Route
            path={"/books"}
            element={<BooksPage booksData={sel.books.booksData} />}
          />
          <Route path={"/book/:id"} element={<BookPage />} />
          <Route path={"/blog/:id"} element={<BlogPage />} />
          <Route path={"*"} element={<NotFoundPage />} />
        </Routes>
      </Suspense>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
