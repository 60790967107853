import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

function BlogCard({ blogData }) {
  useEffect(() => {
    AOS.init({ duration: 1700 });
  });
  return (
    <Link
      data-aos="flip-left"
      to={`/blog/${blogData.id}`}
      className="shadow text-decoration-none d-block h-100 rounded overflow-hidden"
    >
      <img className="w-100" src={blogData.imgSrc} alt="blog img" />
      <div className="p-3">
        <h3
          className="text-danger my-3 font2 fw-bold"
          style={{ minHeight: "50px" }}
        >
          {blogData.title}
        </h3>
        <p className="text-black ellip" style={{ textAlign: "justify" }}>
          {blogData.text}
        </p>
      </div>
    </Link>
  );
}

export default BlogCard;
