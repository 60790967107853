import React, { useEffect } from "react";
import "./style.css";
import { Col, Container, Row } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
function AboutOfSection({ booksData }) {
  useEffect(() => {
    AOS.init({ duration: 1700 });
  });
  return (
    <section className="container-flude sec">
      <Container>
        <Row className="align-items-center justify-content-between">
          <Col
            data-aos="fade-down-left"
            xm="12"
            lg="6"
            className="text-center text-lg-end p-lg-5 p-3 pe-lg-0 ps-lg-0"
          >
            <p className="text-danger m-0 mb-2 font2">
              الدكتور إسماعيل السلامات باحث في علم النسب العربي
              والأنثروبولوجياالسُعودية
            </p>

            <h1 className="display-6 fw-bold font2 ">
              دكتوراه في علم الاجتماع "البدوي" والأنثروبولوجيا، قسم علم الاجتماع
              - جامعة دمشق
            </h1>
            <p className="my-4">
              تمرُّ المُجتمعاتُ العربيَّةُ الراهنة بنقلةٍ نوعيةٍ مِن التطوراتِ
              في بُنيتها المُجتمعيّة، ويأتي الُمجتمع العربي السُّعودي في طليعتها
              وفق التطورات السريعة والعميقة التي يمرُّ بها في ظل إستراتيجية خطط
              التنمية المُستدامة لرؤيةِ (2030) السُّعوديّة برعايةِ خادم الحرمين
              الشّريفين الملك سلمان بن عبد العزيز آل سعود ، وولي عهده الأمين
              صاحب السمو الملكي الأمير محمد بن سلمان آل سعود حفظهما الله.
              وتُعدُّ تلك التطورات والتغيرات أرض خصبة تستهوي المُهتمين من
              الأنثروبولوجيين العرب ،وغيرهم في "علم الأنثروبولوجيا السُّعوديّة"،
              باعتبارهِ الميدان العلمي الأقدر على دراسةِ ظواهرِ التغيُّر والتطور
              الاجتماعي والاقتصادي والحضاري في المُجتمع السُّعودي، وتَتَبُع
              أصوله الأنثروبولوجية - تاريخياً واجتماعياً - وتحليلها، ثمَّ
              مُقارنتِها مع إنجازاتِ الحاضرِ الزاهر، بهدف ربطها ببناءِ المُستقبل
              الواعد في ظلِ إنجازات القيادة السُّعودية الرشيدة. وبفضل الله؛ ثمَّ
              بولاءٍ لولاةِ الأمرِ حفظهما الله، وبِمُقتضى التخصُّص الأكاديمي
              العالي في "الأنثروبولوجيا القبلية"، و التفرد بهِ، ونتيجة للمُعايشة
              بالمُشاركة في المُجتمع السُّعودي لسنواتٍ عديدةٍ مضت، أُنجزَت -
              بحمد الله - دراساتٌ علميَّةٌ عديدة وأصيلة في "الأنثروبولوجيا
              الاجتماعيَّة و التَّاريخيَّة" الخاصة بالمُجتمع السُّعودي العريق في
              أصالةِ حُكَّامِهِ، وعراقةِ وجودهِ، و ثقلهِ العالمي الفاعل, أضعها
              بين يدي الإخوة من الساسة و القراء و الباحثيين وغيرهم
            </p>
            <h4 className="mb-4 font2 fs-5 text-danger">بعض مؤلفات الباحث</h4>
            <Row>
              {booksData.map((item) => (
                <Col key={item.id} xs="4" data-aos="zoom-in-left">
                  <img width="100%" src={item.imgSrc} alt="book imag" />
                </Col>
              ))}
            </Row>
          </Col>
          <Col xm="12" lg="6">
            <img
              data-aos="fade-down-left"
              width="100%"
              src="https://i.ibb.co/qkPw6Lv/image.png"
              alt="book img"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AboutOfSection;
