import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./style.css";
import AOS from "aos";
import "aos/dist/aos.css";

function ContactUsSection() {
  useEffect(() => {
    AOS.init({ duration: 1700 });
  });
  return (
    <Container className="bg-img p-lg-5 p-3 my-5" data-aos="zoom-in">
      <Row className="bg-white p-lg-5 p-3 mx-2 align-items-center justify-content-between">
        <Col xs="12" lg="5">
          <h2 className="fs-1 font2">
            ارسل رسالة الى الباحث :{" "}
            <p className="text-danger"> د.إسماعيل السلامات</p>
          </h2>
          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p> */}
        </Col>
        <Col xs="12" lg="6">
          <form
            action="https://formsubmit.co/feras.s.alhelo@gmail.com"
            method="POST"
          >
            <label for="name">الإسم : </label>
            <br />
            <input type="text" name="Name" id="name" className="mb-3" />
            <br />
            <label for="email">الإيميل :</label>
            <br />
            <input type="email" name="email" id="email" className="mb-3" />
            <br />
            <label for="masseg">أدخل رسالتك </label>
            <br />
            <input
              rows="10"
              type="text"
              name="masseg"
              id="masseg"
              className="p-2 py-3 border w-100"
            />
            <input
              type="hidden"
              name="_captcha"
              value="false"
              className="mb-3"
            />

            <input
              type="submit"
              className="bg-danger rounded-0 btn py-2 px-5 mt-4 fs-4 text-white d-block"
              value="إرسال"
            />
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default ContactUsSection;
