import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

function HeroSection() {
  useEffect(() => {
    AOS.init({ duration: 1700 });
  });
  return (
    <section
      className="container-flued pt-5 position-relative py-5"
      style={{
        backgroundImage:
          "url(https://i.ibb.co/2Z3GVwh/white-wrinkled-paper-background-texture-e1628081582630.jpg)",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        className="position-absolute top-0 left-0 w-100 h-100"
        style={{
          backgroundColor: "transparent",
          backgroundImage: "linear-gradient(180deg, #FFFFFF 0%, #D2D2D200 50%)",
        }}
      ></div>
      <Container>
        <Row
          className="d-flex align-items-center position-relative"
          style={{ ZIndex: "99" }}
        >
          <Col
            sm="12"
            lg="6"
            className="text-center text-lg-end ps-4"
            data-aos="fade-down"
          >
            <p className=" text-danger m-0 mb-2 font2">
              أبرز ما مرت به الدولة السعودية الأولى
            </p>
            <h1 className="fw-bold font2">
              الدولة السعودية الأولى وقيمها المجتمعية
            </h1>
            <h4 className="mt-4 mb-3 fw-bold text-danger">
              رحلة تارخية في تأسيس الامام محمد للدولة السعودية الاولى
            </h4>{" "}
            <p className="fs-4 ">
              يتحدث الكتاب عن تأسيس الإمام محمد بن سعود بن محمد ال سعود الدولة
              السعودية الأولى في عاصمتها الدرعية (1139هـ) والذي وصف أحوال
              الجزيرة العربية قبل تأسيس الدولة السعودية الأولى وبعد بناء دولة
              سعودية مركزية قوية حققت إنجازات عظيمة في نطاق الامن المجتمعي
              الشامل و الإستقرار الإجتماعي و العدل و المساواة في المجتمع السعودي
            </p>
            <Button
              className="bg-danger px-5 py-3 fw-bold my-5 border-0"
              as={Link}
              to="/books"
            >
              تصفح المكتبة
            </Button>
          </Col>
          <Col sm="12" lg="6" data-aos="fade-down">
            <img
              className="mw-100"
              width="100%"
              src="https://i.ibb.co/L9qMC2P/11.png"
              alt="book img"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default HeroSection;
