import React from "react";
import { Row } from "react-bootstrap";

function Sppiner() {
  return (
    <Row className="justify-content-center my-5 py-5 align-items-center">
      <div className="spinner-border text-danger" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </Row>
  );
}

export default Sppiner;
